import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import SignUp from './Views/SignUp';
import SignIn from './Views/SignIn';
import Dashboard from './Views/Dashboard';
import ForgottenPassword from './Views/ForgottenPassword';
import DSubs from './Views/TableTest';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/DSubs" component={DSubs} />
      <Route path="/forgotpassword" component={ForgottenPassword} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/signup" component={SignUp} />
      <Route path="/" component={SignIn} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
